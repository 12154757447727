<!-- eslint-disable vue/valid-v-else -->
<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <div v-if="taskLocal.id">
            <b-button
              v-if="taskLocal.status == 'Kész'"
              size="sm"
              variant="outline-success"
              @click="updateStatus('Folyamatban')"
            >
              Kész
            </b-button>
            <span v-else>
              <b-button
                size="sm"
                variant="outline-secondary"
                @click="updateStatusToFinished(taskLocal.id)"
                v-if="taskLocal.work_id != 'Saját feladat'"
              >
                Késznek jelölés
              </b-button>
              <b-button
                size="sm"
                variant="outline-secondary"
                @click="updateStatus('Kész')"
                v-else
              >
                Késznek jelölés
              </b-button>
            </span>
            <b-button
              v-if="taskLocal.status == 'Folyamatban' && !taskLocal.in_progress && taskLocal.target_id == getUserID()"
              size="sm"
              variant="primary"
              class="ml-1"
              @click="in_progress(taskLocal.id);"
            >
              Folyamatban
            </b-button>
          </div>
          <h5
            v-else
            class="mb-0"
          >
            Feladat létrehozás
          </h5>
          <div>
            <feather-icon
              v-show="taskLocal.id"
              v-if="taskLocal.deleted == 0"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-task');taskLocal.deleted=1"
            />
            <feather-icon
              v-show="taskLocal.id"
              v-else
              icon="RefreshCcwIcon"
              class="cursor-pointer"
              @click="$emit('restore-task');;taskLocal.deleted=0"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <b-form-group
              label="Megnevezés"
              label-for="task-title"
            >
              <b-form-input
                id="task-title"
                v-model="taskLocal.title"
                autofocus
                trim
                placeholder="Feladat megnevezése"
              />
            </b-form-group>

            <b-form-group
              v-if="taskLocal.work_id != 'Nem projekttel kapcsolatos' && taskLocal.work_id != 'Saját feladat'"
              label="Munka azonosító"
              label-for="work_number"
            >
              <b-form-input
                id="work_number"
                v-model="taskLocal.work_number"
                autofocus
                trim
                placeholder="Munka azonosító"
              />
            </b-form-group>

            <b-form-group
              v-if="taskLocal.work_id != 'Nem projekttel kapcsolatos' && taskLocal.work_id != 'Saját feladat'"
              label="Megrendelő"
              label-for="customer"
            >
              <b-form-input
                id="customer"
                v-model="taskLocal.customer"
                autofocus
                trim
                placeholder="Megrendelő"
              />
            </b-form-group>
            <!-- Assignee -->
            <b-form-group
              v-if="taskLocal.work_id != 'Saját feladat'"
              label="Felelős"
              label-for="assignee"
            >
              <v-select
                v-model="taskLocal.assignee"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="preparatoryOptions"
                label="name"
                value="id"
                class="assignee-selector"
                input-id="assignee"
              />
              <small>Feladatot kiadta: <span class="badge badge-primary" style="cursor:pointer" @click="changeAssigneeToCreator(taskLocal.creator_id, taskLocal.creator_name)">{{taskLocal.creator_name}}</span></small>
            </b-form-group>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              name="Due Date"
            >

              <b-form-group
                label="Határidő"
                label-for="due-date"
              >
                <b-form-input
                  v-model="taskLocal.end_date"
                  type="date"
                  class="form-control"
max="9999-12-31"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Prioritás"
              label-for="priority"
            >
              <v-select
                v-model="taskLocal.priority"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :close-on-select="true"
                :options="tagOptions"
                :reduce="option => option.value"
                input-id="priority"
              />
            </b-form-group>

            <!-- Description -->
            <b-form-group v-if="checkOwner(taskLocal.creator_id)"
              label="Leírás"
              label-for="task-description"
            >
              <quill-editor
                id="quil-content"
                v-model="taskLocal.description"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>
            <div v-else-if="taskLocal.description !== null">
              <p><strong>Leírás</strong></p>
              <p v-html="taskLocal.description"></p>
            </div>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskLocal.id ? 'Frissítés' : 'Hozzáadás ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Visszaállítás
              </b-button>
            </div>
          </b-form>
        </validation-observer>
        <!-- /Form -->
        <div v-if="taskLocal.id != null">
          <b-modal id="createEmail" title="E-mail rögzítés" hide-footer>
            <b-row class="my-2 pl-1 pr-1">
              <b-col lg="12">
                <b-form-group
                  label="Megnevezés"
                  label-for="email_title"
                >
                  <b-form-input
                    id="email_title"
                    v-model="email_title"
                    class="w-100"
                    placeholder="Bármi megnevezés amiről belehet azonosítani"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group
                  label="Link"
                  label-for="email_link"
                >
                  <b-form-input
                    id="email_link"
                    v-model="email_link"
                    class="w-100"
                    placeholder="E-mail linkje"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group
                  label="Bejövő vagy kimenő"
                  label-for="email_incomming"
                >
                  <b-form-select
                    id="email_incomming"
                    v-model="email_incomming"
                    :options="incommingOptions"
                    class="w-100"
                    placeholder="E-mail linkje"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="justify-content-end d-flex my-2">
                <b-button size="sm" variant="danger" @click="$bvModal.hide('createEmail')">
                  Mégsem
                </b-button>
                <b-button class="ml-1" size="sm" variant="success" @click="saveEmail()">
                  Rögzítés
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
          <div class="col-lg-12">
            <h5 class="d-inline">Emailek</h5>
            <span class="float-right badge badge-primary" v-b-modal.createEmail>Új email</span>
            <div class="mail-box mt-1" v-if="emails.length > 0">
              <div class="w-100" v-for="email in emails" :key="email.id">
                <div class="row col-lg-12 align-items-center">
                  <p class="col-lg-2 mb-0">
                    <feather-icon
                      icon="MailIcon"
                      size="30"
                      class="text-primary"
                    />
                  </p>
                  <p style="line-height: 1.0;" class="col-lg-9 mb-0 pb-0">
                    <a target="_blank" :href="email.link">{{email.title}}</a>
                    <br>
                    <small v-if="email.incomming==1">Bejövő</small>
                    <small v-else>Kimenő</small>
                  </p>
                  <p class="col-lg-1 mb-0">
                    <feather-icon
                      @click="deleteEmail(email.id)"
                      style="cursor: pointer;"
                      icon="XIcon"
                      size="20"
                      class="text-danger"
                    />
                  </p>
                </div>
                <hr>
              </div>
            </div>
            <div v-else>
              <p class="text-center">Nincsenek emailek rögzítve</p>
              <hr>
            </div>
          </div>
        </div>
        <div v-if="taskLocal.id != null">
          <b-modal id="createComment" title="Új megjegyzés hozzáfűzése" hide-footer>
            <b-row class="my-2 pl-1 pr-1">
              <b-col lg="12">
                <b-form-group
                  label="Megjegyzés"
                  label-for="comment_text"
                >
                  <b-form-textarea
                    id="comment_text"
                    v-model="comment_text"
                    class="w-100"
                    placeholder="Bármi feladahoz kapcsolódó megjegyzés"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="justify-content-end d-flex my-2">
                <b-button size="sm" variant="danger" @click="$bvModal.hide('createComment')">
                  Mégsem
                </b-button>
                <b-button class="ml-1" size="sm" variant="success" @click="saveComment()">
                  Rögzítés
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
          <div class="col-lg-12 mt-2 pb-0">
            <h5 class="d-inline">Megjegyzések</h5>
            <span class="float-right badge badge-primary" style="cursor:pointer" v-b-modal.createComment>Új megjegyzés</span>
            <div class="mail-box mt-1" v-if="comments.length > 0">
              <div class="w-100" v-for="(comment, index) in comments" :key="index">
                <div class="row col-lg-12">
                  <p class="col-lg-2 mb-0">
                    <b-avatar
                      size="32"
                      :variant="`light-primary`"
                      :text="avatarText(comment.name)"
                    />
                  </p>
                  <p style="line-height: 1.0;" class="col-lg-10 mb-0 pb-0 text-primary">{{comment.name}} <br><small>{{comment.created_at}}</small></p>
                  <p class="col-lg-12" style="padding-top:4px"><small>{{comment.comment}}</small></p>
                </div>
              </div>
            </div>
            <div class="mt-1" v-else>
              <p class="text-center">Nincsenek megjegyzések</p>
            </div>
          </div>
        </div>
        <div v-if="taskLocal.id != null">
          <b-modal id="updateFile" title="Új fájl feltöltése" hide-footer>
            <b-row class="my-2 pl-1 pr-1">
              <b-col lg="12">
                <b-form-group
                  label="Fájl neve amiről beazonosítható"
                  label-for="file_name"
                >
                  <b-form-input
                    id="file_name"
                    v-model="file_name"
                    class="w-100"
                    placeholder="Fontos szerződés"
                    @change="onFileChange"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group
                  label="Fájl (.pdf kiterjesztésű engedélyezett)"
                  label-for="file"
                >
                  <b-form-file
                    id="file"
                    v-model="file"
                    accept="application/pdf"
                    multiple
                    class="w-100"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="justify-content-end d-flex my-2">
                <b-button size="sm" variant="danger" @click="$bvModal.hide('updateFile')">
                  Mégsem
                </b-button>
                <b-button class="ml-1" size="sm" variant="success" @click="saveFile()">
                  Feltöltés
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
          <div class="col-lg-12 mt-2 pb-5">
            <h5 class="d-inline">Csatolt fájlok</h5>
            <span class="float-right badge badge-primary" style="cursor:pointer" v-b-modal.updateFile>Fájl feltöltés</span>
            <div class="mail-box mt-1" style="max-width:100%" v-if="files.length > 0">
              <div v-for="(file, index) in files" :key="index">
                <span style="cursor:pointer" @click="downloadPdf(file.id)" class="text-primary mb-2 mr-2">
                  <feather-icon
                    style="cursor: pointer;"
                    icon="FileTextIcon"
                    size="12"
                    class="text-primary"
                  />
                  {{file.name}} ({{file.created_at}})
                </span>
              </div>
            </div>
            <div class="mt-1" v-else>
              <p class="text-center">Nincsenek csatolt fájlok</p>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BFormSelect, BRow, BCol, BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useTaskHandler from './useTaskHandler'
import Swal from 'sweetalert2'

export default {
  components: {
    // BSV
    BFormSelect,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormTextarea,
    BFormFile,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: ['isTaskHandlerSidebarActive', 'task', 'clearTaskData'],
  data() {
    return {
      comment_text: '',
      file_name: '',
      file: null,
      files: [],
      comments: [],
      emails: [],
      email_title: null,
      email_incomming: null,
      email_link: null,
      projectOptions: [],
      required,
      email,
      url,
      preparatoryOptions: [],
      incommingOptions: [
        {
          text: 'Bejövő',
          value: true,
        }, {
          text: 'Kimenő',
          value: false,
        },
      ],
    }
  },
  mounted() {
    (async () => {
      this.assigneeOptions().then(res => {
        this.preparatoryOptions = res
      })
    })()

    axios({
      method: 'get',
      
      url: 'project/list/all',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.projectOptions.push({
        value: 0,
        text: 'Nem tartozik projekthez',
      })
      res.data.forEach(project => {
        this.projectOptions.push({
          value: project.id,
          text: project.name,
        })
      })
    })
  },
  watch: {
    isTaskHandlerSidebarActive(val) {
      if (val) {
        this.getEmails()
        this.getComments()
        this.getFiles()
      }
    },
  },
  methods: {
    getUserID() {
      return JSON.parse(localStorage.getItem('user')).id
    },
    updateStatusToFinished(id) {
      this.$emit('updateStatusToFinished', id)
    },
    in_progress(id) {
      axios({
        method: 'post',
        url: `project/task/in_progress/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          comment: this.comment_text,
          task_id: this.task.id,
          project_id: 0,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen jelezve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    changeAssigneeToCreator(id, name) {
      this.taskLocal.assignee.id = id
      this.taskLocal.assignee.name = name
    },
    checkOwner(id) {
      if (id !== undefined && JSON.parse(localStorage.getItem('user')).id === id) {
        return true
      }
      return false
    },
    downloadPdf(id) {
      axios({
        method: 'post',
        
        url: 'project/file/download',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
        data: {
          id,
        },
      }).then(res => {
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'file.pdf'
        link.click()

        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen letöltve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getComments()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    onFileChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.file = e.target.files[0]
    },
    saveFile() {
      const formData = new FormData()
      if (this.file != null) {
        this.file.forEach(file => {
          formData.append('file[]', file)
        })
      }
      formData.append('task_id', this.task.id)
      formData.append('project_id', this.task.project_id)
      formData.append('file_name', this.file_name)

      axios({
        method: 'post',
        url: 'project/file/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen feltöltve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.file_name = null
        this.$bvModal.hide('updateFile')
        this.getFiles()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    saveComment() {
      axios({
        method: 'post',
        
        url: 'project/comment/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          comment: this.comment_text,
          task_id: this.task.id,
          project_id: 0,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen létrehozva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.comment_text = null
        this.$bvModal.hide('createComment')
        this.getComments()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    getComments() {
      axios({
        method: 'get',
        
        url: `project/comment/list/${this.task.project_id}/${this.task.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.comments = res.data
      })
    },
    getFiles() {
      axios({
        method: 'get',
        
        url: `project/file/list/${this.task.project_id}/${this.task.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.files = res.data
      })
    },
    deleteComment(id) {
      axios({
        method: 'delete',
        
        url: `project/comment/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getComments()
      })
    },
    getEmails() {
      axios({
        method: 'get',
        
        url: `project/email/list/${this.task.project_id}/${this.task.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.emails = response.data
      })
    },
    deleteEmail(id) {
      axios({
        method: 'delete',
        
        url: `project/email/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getEmails()
      })
    },
    saveEmail() {
      axios({
        method: 'post',
        
        url: 'project/email/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          project_id: 0,
          project_task_id: this.task.id,
          title: this.email_title,
          incomming: this.email_incomming,
          link: this.email_link,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen létrehozva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.email_title = null
        this.email_incomming = null
        this.email_link = null
        this.$bvModal.hide('createEmail')
        this.getEmails()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,
      // UI
      assigneeOptions,
      onSubmit,
      updateStatus,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Írj egy leírást...',
    }

    return {
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      tagOptions,
      updateStatus,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
.mail-box{
  max-height:300px;
  overflow:auto;
}
.mail-box::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.mail-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(179, 179, 179);
  border-radius: 10px;
}
/* Handle */
.mail-box::-webkit-scrollbar-thumb {
  background: #3e74c7;
  border-radius: 10px;
}

/* Handle on hover */
.mail-box::-webkit-scrollbar-thumb:hover {
  background: #3e74c7;
}
</style>
