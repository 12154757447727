<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <p class="lead mb-0 pb-0 pt-2 pl-2">Feladatok</p>
          <hr>
          <div class="add-task w-100 mt-0 pt-0 mb-0 pb-0">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="goToCreateTask()"
            >
              Feladat létrehozás
            </b-button>
          </div>
          <hr>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar');"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>
            <div v-show="checkRole('super-todo')">
              <hr>
              <p class="lead mb-0 pb-0 pt-0 pl-2">Munkatársak feladatai</p>
              <hr/>
              <b-col md="12">
                <b-form-group
                  label="Munkatárs kiválasztása"
                  label-for="employee"
                  class="ml-1 mr-1"
                >
                  <b-form-select
                    id="employee"
                    v-model="employee"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="employee_options"
                  />
                </b-form-group>
              </b-col>
              <b-list-group class="list-group-filters">
                <b-list-group-item
                  v-for="filter in taskFilters2"
                  :key="filter.title + $route.path"
                  :to="filter.route"
                  :active="isDynamicRouteActive(filter.route)"
                  @click="$emit('close-left-sidebar');reload()"
                >
                  <feather-icon
                    :icon="filter.icon"
                    size="18"
                    class="mr-75"
                  />
                  <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                </b-list-group-item>
              </b-list-group>
              <hr>
            </div>
            <!-- Tags -->
            <!--<div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Tags
              </h6>
              <feather-icon icon="PlusIcon" />
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.title + $route.path"
                :to="tag.route"
                :active="isDynamicRouteActive(tag.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group>-->

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkRole } from '@/middleware/authLoader'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    BFormSelect,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      change: false,
      foreman: null,
      employee_options: [],
      employee: this.$route.params.id ?? 0,
      taskFilters2: [
        { title: 'Feladatok', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter.id', params: { filter: 'Összes_Feladatok' } } },
        { title: 'Hamarosan lejáró', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter.id', params: { filter: 'Összes_Hamarosan_lejáró'} } },
        { title: 'Késésben lévő', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter.id', params: { filter: 'Összes_Késésben_lévő' } } },
        { title: 'Kiadott feladatok', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter.id', params: { filter: 'Összes_Kiadott' } } },
        { title: 'Kész', icon: 'CheckIcon', route: { name: 'monolit.global.todo.filter.id', params: { filter: 'Összes_Kész' } } },
        { title: 'Törölt', icon: 'TrashIcon', route: { name: 'monolit.global.todo.filter.id', params: { filter: 'Összes_Törölt' } } },
      ],
    }
  },
  watch: {
    employee(newValue) {
      this.taskFilters2.forEach((filter, index) => {
        this.taskFilters2[index].route.params.id = newValue
      })
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.employee = this.$route.params.id
    }
    axios({
      method: 'get',
      
      url: 'role/list/all',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      this.employee_options = response.data.map(item => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      this.employee_options.push({
        text: 'Minden munkatárs',
        value: 0,
      })
      this.taskFilters2.forEach((filter, index) => {
        this.taskFilters2[index].route.params.id = this.$route.params.id ?? 0
      })
    })
  },
  methods: {
    reload() {
      this.taskFilters2.forEach((filter, index) => {
        this.taskFilters2[index].route.params.id = this.$route.params.id
      })
    },
    goToCreateTask() {
      this.$router.push({ name: 'monolit.projects.create' })
    },
    moveToProject() {
      axios({
        method: 'put',
        
        url: `global/status/start/${this.$route.params.id}`,
        data: {
          foreman_id: this.foreman,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen projekt státuszba került',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.comment_text = null
        this.$bvModal.hide('modal_to_project')
        this.$router.push({
          name: 'monolit.projects.list',
        })
        this.getComments()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    restorePreparatoryFeedback() {
      axios({
        method: 'put',
        
        url: `global/preparatory/restore/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen visszautasítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.comment_text = null
        this.$bvModal.hide('createComment')
        this.getComments()
      })
    },
    preparatoryFeedback() {
      axios({
        method: 'put',
        
        url: `global/preparatory/done/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Visszajelzés elküldve a vezetőség felé',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.comment_text = null
        this.$bvModal.hide('createComment')
        this.getComments()
      })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = [
      { title: 'Feladataim', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Feladataim' } } },
      { title: 'Saját feladatok', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Saját feladat' } } },
      { title: 'Hamarosan lejáró', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Hamarosan_lejáró' } } },
      { title: 'Késésben lévő', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Késésben_lévő' } } },
      { title: 'Kiadott feladatok', icon: 'UserIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Kiadott' } } },
      // { title: 'Azonnal', icon: 'StarIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Azonnal' } } },
      { title: 'Kész', icon: 'CheckIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Kész' } } },
      { title: 'Törölt', icon: 'TrashIcon', route: { name: 'monolit.global.todo.filter', params: { filter: 'Törölt' } } },
      // { title: 'Törölt', icon: 'TrashIcon', route: { name: 'monolit.preparation.todo.filter', params: { filter: 'deleted' } } },
    ]

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
      checkRole,
    }
  },
}
</script>

<style>
  .add-task{
    bottom:0px;
  }
</style>
