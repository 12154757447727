<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Feladat keresése"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="resetSortAndNavigate">
              Alapérzelmettre állítás
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title|asc' } }">
              Cím szerint növekvő
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title|desc' } }">
              Cím szerint csökkenő
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'end_date|asc' } }">
              Lejárat szerint növekvő
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'end_date|asc' } }">
              Lejárat szerint csökkenő
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="tasks"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <div
            v-for="(task, index) in tasks"
            :key="index"
            class="mt-1"
          >
            <p
              class="pl-2 ml-1"
              style="cursor:pointer"
              @click="toggleTasks('task-'+index)"
            >
              <span class="badge" :class="`badge-${tagVariant(task)}`"><span v-if="task[0].work_number != 'null'">{{task[0].work_number}}</span> <span v-show="index==='Nem projekttel kapcsolatos' || index === 'Saját feladat'">{{ index }}</span></span>
              <span class="badge badge-primary float-right mr-2">{{ task.length }} Feladat</span>
            </p>
            <div v-bind:id="'task-'+index" class="d-none d-block">
              <li
                v-for="(sub_task, index2) in task"
                :key="index2"
                class="todo-item"
                :class="`bg-light-${resolveTagVariant(sub_task.priority)}`"
                @click="handleTaskClick(sub_task)"
              >
                <div class="todo-title-wrapper">
                  <div class="todo-title-area justify-content-between">
                    <div class="title-wrapper">
                      <span class="todo-title">{{ sub_task.title }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <b-avatar
                        v-if="sub_task.creator_name"
                        size="32"
                        class="d-block d-md-none"
                        :src="null"
                        :variant="`light-${resolveAvatarVariant(sub_task.status)}`"
                        :text="avatarText(sub_task.creator_name)"
                      />
                      <b-avatar
                        v-else
                        size="32"
                        class="d-block d-md-none"
                        variant="light-secondary"
                      >
                        <feather-icon
                          icon="UserIcon"
                          size="16"
                        />
                      </b-avatar>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        class="d-block d-md-none mr-0"
                      />
                      <b-avatar
                        v-if="sub_task.target_name"
                        class="d-block d-md-none"
                        size="32"
                        :src="null"
                        :variant="`light-${resolveAvatarVariant(sub_task.status)}`"
                        :text="avatarText(sub_task.target_name)"
                      />
                      <b-avatar
                        v-else
                        class="d-block d-md-none"
                        size="32"
                        variant="light-secondary"
                      >
                        <feather-icon
                          icon="UserIcon"
                          size="16"
                        />
                      </b-avatar>
                    </div>
                  </div>
                  <div class="todo-item-action">
                    <div class="badge-wrapper mr-1">
                      <b-badge
                        pill
                        :variant="`light-${resolveTagVariant(sub_task.priority)}`"
                        class="text-capitalize"
                      >
                        {{ sub_task.priority }}
                      </b-badge>
                      <b-badge
                        v-if="sub_task.status != 'Folyamatban'"
                        pill
                        :variant="`light-${resolveTagVariant(sub_task.status)}`"
                        class="text-capitalize"
                      >
                        {{ sub_task.status }}
                      </b-badge>
                      <b-badge
                        v-if="sub_task.status == 'Folyamatban' && sub_task.in_progress"
                        pill
                        :variant="`light-${resolveTagVariant(sub_task.status)}`"
                        class="text-capitalize"
                      >
                        {{ sub_task.status }}
                      </b-badge>
                    </div>
                    <small class="d-none d-md-block text-nowrap text-muted mr-1">{{ sub_task.end_date }}</small>
                    <b-avatar
                      v-if="sub_task.creator_name"
                      size="32"
                      class="d-none d-md-block"
                      :src="'https://monolitcrm.hu/images/'+sub_task.creator_id+'.jpg'"
                      :variant="`light-${resolveAvatarVariant(sub_task.status)}`"
                      :text="avatarText(sub_task.creator_name)"
                      :title="sub_task.creator_name"
                    />
                    <b-avatar
                      v-else
                      size="32"
                      class="d-none d-md-block"
                      variant="light-secondary"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                      />
                    </b-avatar>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                      class="d-none d-md-block mr-0"
                    />
                    <b-avatar
                      v-if="sub_task.target_name"
                      class="d-none d-md-block"
                      size="32"
                      :src="'https://monolitcrm.hu/images/'+sub_task.target_id+'.jpg'"
                      :variant="`light-${resolveAvatarVariant(sub_task.status)}`"
                      :text="avatarText(sub_task.target_name)"
                      :title="sub_task.target_name"
                    />
                    <b-avatar
                      v-else
                      class="d-none d-md-block"
                      size="32"
                      variant="light-secondary"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                      />
                    </b-avatar>
                  </div>
                </div>
                <p v-if="sub_task.description != null" class="mb-0" style="padding-left:10px;padding-top:5px" v-html="sub_task.description.replace(/<[^>]*>?/gm, '').substring(0,100)"></p>
              </li>
            </div>
            <hr>
          </div>
        </draggable>
        <div
          class="no-results"
          :class="{'show': tasks.length != null}"
        >
          <h5>Nincs találat</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      @remove-task="removeTask"
      @add-task="addTask"
      @fetch-tasks="fetchTasks"
      @updateStatusToFinished="updateStatusToFinished"
      @update-task="updateTask"
      @restore-task="restoreTask"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        :project="project"
        :task-tags="taskTags"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted, onMounted, onBeforeMount,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BBadge, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
// eslint-disable-next-line import/no-cycle
import { formatDate, avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  setup() {
    const toast = useToast()
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)

    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks()
    })

    const fetchTasks = () => {
      axios({
        method: 'get',
        
        url: 'global/task/list/0',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          status: routeParams.value.filter ?? 'Feladataim',
          id: routeParams.value.id ?? null,
          q: searchQuery.value,
          sort: sortBy.value,
        },
      }).then(response => {
        tasks.value = response.data
      })
    }

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const tasks = ref([])

    const project = ref({})

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    }

    const blankTask = {
      id: null,
      title: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      axios({
        method: 'post',
        
        url: 'project/task/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          project_id: 0,
          title: val.title,
          description: val.description,
          start_date: val.start_date,
          end_date: val.end_date,
          target_id: val.assignee.id,
          priority: val.priority,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen létrehozva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    }
    const removeTask = () => {
      axios({
        method: 'delete',
        
        url: `project/task/delete/${task.value.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    }
    const updateStatusToFinished = id => {
      Swal.fire({
        title: 'Mi lett a végeredmény?',
        showDenyButton: false,
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off',
        },
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-danger mr-1',
        },
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Tovább',
        cancelButtonText: 'Mégsem',
      }).then(result => {
        if (result.isDenied) {
          return null
        }
        if (result.isConfirmed) {
          axios({
            method: 'post',
            
            url: `project/task/done-with-message/${id}`,
            data: {
              text: result.value,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then(() => {
            fetchTasks()
            isTaskHandlerSidebarActive.value = false
            toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeresen jelezve',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Minden mező kitöltése kötelező',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
    const restoreTask = () => {
      axios({
        method: 'put',
        
        url: `project/task/restore/${task.value.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen visszaállítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    }
    const updateTask = val => {
      axios({
        method: 'put',
        
        url: 'project/task/update',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          id: val.id,
          project_id: val.project_id,
          title: val.title,
          description: val.description,
          start_date: val.start_date,
          end_date: val.end_date,
          target_id: val.assignee.id,
          priority: val.priority,
          work_id: val.work_id,
          work_number: val.work_number,
          customer: val.customer,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const taskTags = [
      { title: 'Előkészület', color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'Előkészület' } } },
      { title: 'Kész', color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'Kész' } } },
      { title: 'Hamarosan lejár', color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'Hamarosan lejár' } } },
      { title: 'Lejárt', color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'Lejárt' } } },
      { title: 'Folyamatban', color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'Folyamatban' } } },
    ]

    const resolveTagVariant = tag => {
      if (tag === 'Előkészület') return 'primary'
      if (tag === 'Kész') return 'success'
      if (tag === 'Hamarosan lejár') return 'warning'
      if (tag === 'Lejárt') return 'danger'
      if (tag === 'Folyamatban') return 'info'
      if (tag === 'Alacsony') return 'info'
      if (tag === 'Azonnal') return 'danger'
      if (tag === 'Fontos') return 'warning'
      if (tag === 'Közepes') return 'primary'
      return 'primary'
    }

    const tagVariant = tags => {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].priority === 'Azonnal') return 'danger'
      }

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].priority === 'Fontos') return 'warning'
      }

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].priority === 'Közepes') return 'primary'
      }

      return 'info'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('Előkészület')) return 'primary'
      if (tags.includes('Hamarosan lejár')) return 'warning'
      if (tags.includes('Kész')) return 'success'
      if (tags.includes('Lejárt')) return 'danger'
      if (tags.includes('Folyamatban')) return 'info'
      return 'primary'
    }

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    if (routeParams.value.id && (routeParams.value.filter === 'Kész' || routeParams.value.filter === 'Feladataim' || routeParams.value.filter === 'Kiadott')) {
      axios({
        method: 'get',
        
        url: `project/task/get/${routeParams.value.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        handleTaskClick(res.data)
      })
    }

    const removeID = text => {
      return text.replace(/\d+/g, '').replace('#-', '')
    }

    const handleTaskClick = taskData => {
      task.value = taskData
      task.value.assignee = { id: taskData.target_id, name: taskData.target_name }
      isTaskHandlerSidebarActive.value = true
    }

    const toggleTasks = index => {
      const element = document.getElementById(index)
      element.classList.toggle('d-block')
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted
      updateTask(taskData)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    fetchTasks()

    return {
      toggleTasks,
      task,
      tasks,
      removeTask,
      addTask,
      updateTask,
      restoreTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,
      project,
      updateStatusToFinished,

      // UI
      removeID,
      tagVariant,
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
